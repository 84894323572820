import React, { useState, useEffect, useCallback, memo } from 'react';
import axios from 'axios';
import { Tab } from '@headlessui/react';
import { Search, CheckCircle, XCircle, AlertTriangle, Info, Zap, Target, BarChart, Clock } from 'lucide-react';

const UrlInput = memo(({ onSubmit, loading, isDarkMode }) => {
  const [localUrl, setLocalUrl] = useState('');

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-3 rounded-lg focus:outline-none focus:shadow-outline transition duration-200 ease-in-out transform hover:scale-103`;

  const handleSubmit = useCallback(() => {
    onSubmit(localUrl);
  }, [localUrl, onSubmit]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <input
        type="text"
        placeholder="Enter website URL (e.g., example.com)"
        value={localUrl}
        onChange={(e) => setLocalUrl(e.target.value)}
        onKeyPress={handleKeyPress}
        className={`${inputClass} flex-grow`}
      />
      <button
        onClick={handleSubmit}
        disabled={loading}
        className={`${buttonClass} ${loading ? 'opacity-50 cursor-not-allowed' : ''} whitespace-nowrap px-6`}
      >
        {loading ? 'Auditing...' : 'Perform Audit'}
        {!loading && <Search size={24} className="inline-block ml-2" />}
      </button>
    </div>
  );
});

const SEOAuditTool = ({ isDarkMode }) => {
  const [auditResults, setAuditResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [auditHistory, setAuditHistory] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);




  const fetchAuditHistory = useCallback(async () => {
    try {
      const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/seo-audit-history');
      setAuditHistory(response.data);
    } catch (err) {
      console.error('Failed to fetch audit history:', err);
    }
  }, []);

  useEffect(() => {
  fetchAuditHistory();
}, [fetchAuditHistory]);

const performAudit = useCallback(async (url) => {
  if (!url.trim()) return;
  setLoading(true);
  setError('');
  setAuditResults(null);

  let auditUrl = url.startsWith('http') ? url : `https://${url}`;

  try {
    const response = await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/seo-audit', { url: auditUrl });
    setAuditResults(response.data);
    fetchAuditHistory();
  } catch (err) {
    setError('Failed to perform SEO audit. Please try again.');
  } finally {
    setLoading(false);
  }
}, [fetchAuditHistory]);





  const viewAuditDetails = useCallback((audit) => {
    setAuditResults(audit.results);
    setSelectedTab(0);  // Switch to the Audit tab
  }, []);

  const renderResultItem = (label, value, icon, description = '') => (
    <div className="flex items-start mb-4">
      {icon === 'check' && <CheckCircle className="text-green-500 mr-3 mt-1 flex-shrink-0" size={24} />}
      {icon === 'x' && <XCircle className="text-red-500 mr-3 mt-1 flex-shrink-0" size={24} />}
      {icon === 'warning' && <AlertTriangle className="text-yellow-500 mr-3 mt-1 flex-shrink-0" size={24} />}
      {icon === 'info' && <Info className="text-blue-500 mr-3 mt-1 flex-shrink-0" size={24} />}
      <div>
        <span className="font-semibold">{label}</span>
        <p className="mt-1">{value !== undefined && value !== null ? value : 'N/A'}</p>
        {description && <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">{description}</p>}
      </div>
    </div>
  );

  const renderPageSpeedInsights = () => (
    <div className="mt-8">
      <h3 className="text-2xl font-bold mb-4 flex items-center">
        <Zap className="mr-2" /> Page Speed Insights
      </h3>
      {renderResultItem('First Contentful Paint', `${auditResults.pageSpeed?.fcp?.toFixed(2) || 'N/A'}s`, auditResults.pageSpeed?.fcp < 1.8 ? 'check' : 'warning', 'Time to first contentful paint. Aim for under 1.8 seconds.')}
      {renderResultItem('Time to Interactive', `${auditResults.pageSpeed?.tti?.toFixed(2) || 'N/A'}s`, auditResults.pageSpeed?.tti < 3.8 ? 'check' : 'warning', 'Time until the page becomes fully interactive. Aim for under 3.8 seconds.')}
      {renderResultItem('Speed Index', `${auditResults.pageSpeed?.si?.toFixed(2) || 'N/A'}s`, auditResults.pageSpeed?.si < 3.4 ? 'check' : 'warning', 'How quickly the contents of a page are visibly populated. Aim for under 3.4 seconds.')}
    </div>
  );

  const renderKeywordSuggestions = () => (
    <div className="mt-8">
      <h3 className="text-2xl font-bold mb-4 flex items-center">
        <Target className="mr-2" /> Keyword Suggestions
      </h3>
      <ul className="list-disc pl-6 space-y-2">
        {auditResults.keywordSuggestions.map((keyword, index) => (
          <li key={index}>{keyword}</li>
        ))}
      </ul>
    </div>
  );

  const renderOverallScore = () => {
    const score = auditResults.overallScore;
    let scoreColor = 'text-red-500';
    if (score >= 80) scoreColor = 'text-green-500';
    else if (score >= 60) scoreColor = 'text-yellow-500';

    return (
      <div className="mt-8 text-center mb-8">
        <h3 className="text-2xl font-bold mb-4 flex items-center justify-center">
          <BarChart className="mr-2" /> Overall SEO Score
        </h3>
        <div className={`text-6xl font-bold ${scoreColor}`}>{score}/100</div>
      </div>
    );
  };

  const MainTab = () => (
    <div>
      <div className="max-w-3xl mx-auto mb-8">
        <UrlInput
          onSubmit={performAudit}
          loading={loading}
          isDarkMode={isDarkMode}
        />
      </div>

      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}

      {auditResults && (
        <>
          {renderOverallScore()}
          <div className={`mt-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-xl`}>
            <h2 className="text-3xl font-bold mb-6 text-left">Audit Results</h2>
            {renderResultItem('Title', auditResults.title, auditResults.title.length > 0 ? 'check' : 'x', `Length: ${auditResults.title.length} characters. Ideal length is 50-60 characters.`)}
            {renderResultItem('Meta Description', auditResults.metaDescription, auditResults.metaDescription.length > 0 ? 'check' : 'x', `Length: ${auditResults.metaDescription.length} characters. Ideal length is 150-160 characters.`)}
            {renderResultItem('Headings', `H1: ${auditResults.headings.h1}, H2: ${auditResults.headings.h2}, H3: ${auditResults.headings.h3}`, 'info', 'Ensure a logical heading structure for better content organization.')}
            {renderResultItem('Images with Alt Text', `${auditResults.imagesWithAlt}/${auditResults.totalImages}`, auditResults.imagesWithAlt === auditResults.totalImages ? 'check' : 'warning', 'Alt text improves accessibility and provides context for search engines.')}
            {renderResultItem('Internal Links', auditResults.internalLinks, 'info', 'Internal linking helps with website navigation and distributes page authority.')}
            {renderResultItem('External Links', auditResults.externalLinks, 'info', 'External links to reputable sources can boost credibility and SEO.')}
            {renderResultItem('Load Time', `${auditResults.loadTime.toFixed(2)}s`, auditResults.loadTime < 3 ? 'check' : 'warning', 'Faster load times improve user experience and SEO. Aim for under 3 seconds.')}
            {renderResultItem('Mobile Friendly', auditResults.mobileFriendly ? 'Yes' : 'No', auditResults.mobileFriendly ? 'check' : 'x', 'Mobile-friendliness is crucial for SEO and user experience.')}
            {renderResultItem('SSL Certificate', auditResults.sslCertificate ? 'Valid' : 'Invalid', auditResults.sslCertificate ? 'check' : 'x', 'An SSL certificate is important for security and is a ranking factor for SEO.')}
            {renderResultItem('Robots.txt', auditResults.robotsTxt ? 'Present' : 'Not found', auditResults.robotsTxt ? 'check' : 'warning', 'Robots.txt file guides search engine crawlers on how to crawl your site.')}
            {renderResultItem('Sitemap', auditResults.sitemap ? 'Found' : 'Not found', auditResults.sitemap ? 'check' : 'warning', 'A sitemap helps search engines understand your site structure and find all pages.')}
            {renderResultItem('Schema Markup', auditResults.schemaMarkup ? 'Present' : 'Not detected', auditResults.schemaMarkup ? 'check' : 'warning', 'Schema markup helps search engines understand your content better.')}
            {renderResultItem('Content Length', `${auditResults.contentLength} words`, 'info', 'Longer, high-quality content often performs better in search results.')}
            {renderResultItem(
              'Keyword Density',
              auditResults.keywordDensity.map(kw => `${kw.keyword} (${kw.density.toFixed(2)}%)`).join(', '),
              'info',
              'Shows the most frequent words. Ensure natural use of target keywords.'
            )}
            {renderPageSpeedInsights()}
            {renderKeywordSuggestions()}
          </div>
        </>
      )}
    </div>
  );

  const HistoryTab = () => (
    <div className="mt-8">
      <h2 className="text-3xl font-bold mb-6">Audit History</h2>
      {auditHistory.map((audit) => (
        <div
          key={audit.id}
          onClick={() => viewAuditDetails(audit)}
          className={`mb-6 p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer`}
        >
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-xl font-semibold mb-2">{audit.url}</h3>
              <p className="text-sm text-gray-500 flex items-center">
                <Clock size={16} className="mr-2" />
                {new Date(audit.created_at).toLocaleString()}
              </p>
            </div>
            <div className="flex items-center"> {/* Add a container for vertical centering */}
              <p className={`text-lg font-bold ${audit.results.overallScore >= 80 ? 'text-green-500' : audit.results.overallScore >= 60 ? 'text-yellow-500' : 'text-red-500'}`}>
                {audit.results.overallScore}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );


  return (
    <div className={`container mx-auto px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <h1 className="text-4xl font-bold mb-8">SEO Audit Tool</h1>

      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex space-x-1 mb-8">
          {['Audit', 'History'].map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `rounded-full py-2 px-6 text-base font-medium leading-5 focus:outline-none ${
                  selected
                    ? 'bg-yellow-fill text-white shadow'
                    : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
                }`
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <MainTab />
          </Tab.Panel>
          <Tab.Panel>
            <HistoryTab />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default SEOAuditTool;
