import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Save } from 'lucide-react';

const EmailSettings = ({ isDarkMode }) => {
  const { user } = useAuth0();
  const [settings, setSettings] = useState({
    defaultName: '',
    defaultEmail: '',
    defaultCompany: '',
    defaultPhone: '',
    defaultSignature: '',
  });
  const [isSaving, setIsSaving] = useState(false);

  const fetchSettings = useCallback(async () => {
    try {
      const response = await axios.get(`https://sunlab-8da6b753879c.herokuapp.com/api/email-settings/${user.sub}`);
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching email settings:', error);
    }
  }, [user.sub]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleInputChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleSaveSettings = async () => {
    setIsSaving(true);
    try {
      await axios.post(`https://sunlab-8da6b753879c.herokuapp.com/api/email-settings/${user.sub}`, settings);
      alert('Settings saved successfully');
    } catch (error) {
      console.error('Error saving email settings:', error);
      alert('Failed to save settings');
    }
    setIsSaving(false);
  };

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`;

  return (
    <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 mt-6`}>
      <h2 className="text-2xl font-semibold mb-4">Email Generator Settings</h2>
      <div className="space-y-4">
        <div>
          <label className="block mb-1">Default Name</label>
          <input
            type="text"
            name="defaultName"
            value={settings.defaultName}
            onChange={handleInputChange}
            className={inputClass}
          />
        </div>
        <div>
          <label className="block mb-1">Default Email</label>
          <input
            type="email"
            name="defaultEmail"
            value={settings.defaultEmail}
            onChange={handleInputChange}
            className={inputClass}
          />
        </div>
        <div>
          <label className="block mb-1">Default Company</label>
          <input
            type="text"
            name="defaultCompany"
            value={settings.defaultCompany}
            onChange={handleInputChange}
            className={inputClass}
          />
        </div>
        <div>
          <label className="block mb-1">Default Phone Number</label>
          <input
            type="text"
            name="defaultPhone"
            value={settings.defaultPhone}
            onChange={handleInputChange}
            className={inputClass}
          />
        </div>
        <div>
          <label className="block mb-1">Default Signature</label>
          <textarea
            name="defaultSignature"
            value={settings.defaultSignature}
            onChange={handleInputChange}
            className={`${inputClass} h-24`}
          ></textarea>
        </div>
        <div>
          <label className="block mb-1">Signature Preview</label>
          <div
            className={`${inputClass} h-auto overflow-auto bg-white border border-gray-200`}
            dangerouslySetInnerHTML={{ __html: settings.defaultSignature }}
          ></div>
        </div>
        <button onClick={handleSaveSettings} className={buttonClass} disabled={isSaving}>
          <Save size={20} className="inline-block mr-2" />
          {isSaving ? 'Saving...' : 'Save Settings'}
        </button>
      </div>
    </div>
  );
};

export default EmailSettings;
