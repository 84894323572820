import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { Send, CheckCircle, Search, Calendar, Sparkles, CalendarCheck2, SendHorizontal, Trash, ThumbsUp, ThumbsDown, Mail, X, CheckSquare, StopCircle, Eye, ChevronDown } from 'lucide-react';
import './globals.css';
import { useAuth0 } from '@auth0/auth0-react';
import { motion, AnimatePresence } from 'framer-motion';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const Dropdown = ({ label, options, onSelect, isDarkMode, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedOption = options.find(option => option.value === value);
  const displayText = selectedOption && selectedOption.value !== '' ? selectedOption.label : label;

  return (
    <div className="relative inline-block text-left w-full" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className={`inline-flex justify-between items-center w-full rounded-lg border border-gray-300 shadow-sm px-4 py-2 ${
            isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-700'
          } text-sm font-medium hover:bg-opacity-90`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {displayText}
          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            style={{ transformOrigin: 'center' }}
            className="flex items-center justify-center"
          >
            <ChevronDown className="h-5 w-5" aria-hidden="true" />
          </motion.div>
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className={`origin-top-left absolute left-0 mt-2 w-full rounded-lg shadow-lg ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            } ring-1 ring-black ring-opacity-5 z-50`}
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isDarkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
                  } ${option.value === value ? 'font-bold' : ''}`}
                  role="menuitem"
                  onClick={() => {
                    onSelect(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const EmailGenerator = ({ isDarkMode }) => {
  const { user } = useAuth0();
  const [activeTab] = useState('generate');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [generatedEmails, setGeneratedEmails] = useState(() => {
    try {
      const storedEmails = localStorage.getItem('generatedEmails');
      return storedEmails ? JSON.parse(storedEmails) : [];
    } catch (error) {
      console.error('Error parsing stored emails:', error);
      return [];
    }
  });
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentContactId, setCurrentContactId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [showScheduler, setShowScheduler] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(dayjs());
  const [emailsToSchedule, setEmailsToSchedule] = useState([]);
  const [settings, setSettings] = useState({
    defaultName: '',
    defaultEmail: '',
    defaultCompany: '',
    defaultPhone: '',
    defaultSignature: '',
  });
  const [progress, setProgress] = useState(0);
  const [shouldStopGenerating, setShouldStopGenerating] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewEmail, setPreviewEmail] = useState({
    from: '',
    subject: '',
    body: '',
    signature: ''
  });
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedAssignedTo, setSelectedAssignedTo] = useState('');

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/users-with-settings');
      setUsers(response.data);
      // Set the current user as the default selected user
      const currentUser = response.data.find(u => u.user_id === user.sub);
      if (currentUser) {
        setSelectedUserId(currentUser.user_id);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again.');
    }
  }, [user.sub, setUsers, setSelectedUserId, setError]);

  const fetchScheduledEmails = useCallback(async () => {
      try {
        const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/scheduled-emails');
        setScheduledEmails(response.data);
      } catch (error) {
        console.error('Error fetching scheduled emails:', error);
      }
    }, []);

    useEffect(() => {
      fetchScheduledEmails();
    }, [fetchScheduledEmails]);


    const renderScheduler = () => (
    <ThemeProvider theme={createTheme({
      palette: {
        mode: isDarkMode ? 'dark' : 'light',
        primary: {
          main: '#ffe176', // yellow-fill color
        },
      },
      typography: {
        fontFamily: 'SpaceGrotesk, sans-serif',
      },
      components: {
        MuiPickersDay: {
          styleOverrides: {
            root: {
              borderRadius: '8px',
            },
          },
        },

        MuiButtonBase: {
          styleOverrides: {
            root: {
              borderRadius: '8px',
            },
          },
        },
        MuiPickersPopper: {
          styleOverrides: {
            root: {
              borderRadius: '10px',
            },
          },
        },
        MuiPopper: {
          styleOverrides: {
            root: {
              borderRadius: '10px',
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: '8px',
            },
          },
        },
      },
    })}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-8 rounded-lg w-96 font-SpaceGrotesk flex flex-col items-center`}>
            <h3 className="text-2xl font-bold mb-6 text-center">Schedule {emailsToSchedule.length} Email{emailsToSchedule.length > 1 ? 's' : ''}</h3>
            <div className="w-full flex justify-center mb-8">
              <DateTimePicker
                label="Schedule Date and Time"
                value={scheduledTime}
                onChange={(newValue) => setScheduledTime(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                DialogProps={{
                  PaperProps: {
                    style: {
                      borderRadius: '12px',
                    },
                  },
                }}
              />
            </div>
            <div className="flex justify-center space-x-4 w-full">
              <button
                onClick={() => setShowScheduler(false)}
              className="px-6 py-2 rounded-lg bg-red-fill hover:bg-red-hover border-red-border border-0.5 text-white font-bold transition duration-200"
            >
              Cancel
            </button>
            <button
              onClick={handleScheduleEmails}
              className="px-6 py-2 rounded-lg bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5 text-white font-bold transition duration-200"
            >
              Schedule
            </button>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  </ThemeProvider>
);

const handleScheduleEmails = async () => {
try {
  const promises = emailsToSchedule.map(contactId =>
    axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/schedule-email', {
      contactId,
      emailId: generatedEmails[contactId].id,
      scheduledTime: scheduledTime.toISOString(),
    })
  );
  await Promise.all(promises);
  setShowScheduler(false);
  fetchScheduledEmails();
  setError(`${emailsToSchedule.length} email${emailsToSchedule.length > 1 ? 's' : ''} scheduled successfully`);
  setEmailsToSchedule([]);
} catch (error) {
  console.error('Error scheduling emails:', error);
  setError('Failed to schedule emails. Please try again.');
}
};

  const handleSelectEmailsToSchedule = (contactIds) => {
    setEmailsToSchedule(contactIds);
    setShowScheduler(true);
  };

    const fetchSettings = useCallback(async () => {
      try {
        const response = await axios.get(`https://sunlab-8da6b753879c.herokuapp.com/api/email-settings/${user.sub}`);
        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching email settings:', error);
      }
    }, [user.sub, setSettings]);



  const storeGeneratedEmails = useCallback((emails) => {
    try {
      // Ensure emails is an array
      const emailsArray = Array.isArray(emails) ? emails : [emails];
      
      // Keep only the latest 50 emails
      const latestEmails = emailsArray.slice(-50);
      const serializedEmails = JSON.stringify(latestEmails);
      
      localStorage.setItem('generatedEmails', serializedEmails);
    } catch (error) {
      if (error instanceof DOMException && (error.name === 'QuotaExceededError' || error.name === 'NS_ERROR_DOM_QUOTA_REACHED')) {
        console.warn('Storage quota exceeded. Clearing old data.');
        localStorage.clear();
        try {
          // Try storing only the latest 10 emails
          const reducedEmails = Array.isArray(emails) ? emails.slice(-10) : [emails].slice(-10);
          localStorage.setItem('generatedEmails', JSON.stringify(reducedEmails));
        } catch (innerError) {
          console.error('Error storing reduced set of emails:', innerError);
        }
      } else {
        console.error('Error storing generated emails:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (generatedEmails.length > 0) {
      storeGeneratedEmails(generatedEmails);
    }
  }, [generatedEmails, storeGeneratedEmails]);

  const fetchGeneratedEmails = useCallback(async () => {
    try {
      console.log('Fetching generated emails...');
      const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/generated-emails');
      console.log('Fetched emails:', response.data);
      const emailsById = response.data.reduce((acc, email) => {
        acc[email.contact_id] = { id: email.id, content: email.content, status: email.status };
        return acc;
      }, {});
      console.log('Processed emails:', emailsById);
      setGeneratedEmails(emailsById);
    } catch (error) {
      console.error('Error fetching generated emails:', error);
      setError('Failed to fetch generated emails. Please try again.');
    }
  }, [setGeneratedEmails, setError]);

const handlePreviewEmail = (contactId) => {
  const contact = contacts.find(c => c.id === contactId);
  const emailContent = generatedEmails[contactId]?.content || '';

  setPreviewEmail({
    from: `${settings.defaultName} <${settings.defaultEmail}>`,
    to: `${contact.firstName} ${contact.lastName} <${contact.email}>`,
    subject: 'Reaching out about your website',
    body: emailContent, // The signature is now included in emailContent
  });

  setShowPreview(true);
};

  const handleApproveFromPreview = async () => {
    await handleApproveEmail(currentContactId);
    showNextEmailPreview();
  };

  const handleDenyFromPreview = async () => {
    await handleDenyEmail(currentContactId);
    showNextEmailPreview();
  };

  const showNextEmailPreview = () => {
    const emailIds = Object.keys(generatedEmails);
    const currentIndex = emailIds.indexOf(currentContactId.toString());
    if (currentIndex < emailIds.length - 1) {
      const nextContactId = parseInt(emailIds[currentIndex + 1]);
      handlePreviewEmail(nextContactId);
    } else {
      setShowPreview(false);
    }
  };

  const fetchContacts = useCallback(async () => {
    try {
      const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/contacts');
      if (Array.isArray(response.data)) {
        setContacts(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setContacts([]);
        setError('Failed to fetch contacts. Unexpected data format.');
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
      setContacts([]);
      setError('Failed to fetch contacts. Please try again.');
    }
  }, [setContacts, setError]);

  useEffect(() => {
    fetchContacts();
    fetchGeneratedEmails();
    fetchUsers();
 }, [fetchContacts, fetchGeneratedEmails, fetchUsers]);

 useEffect(() => {
  fetchSettings();
 }, [fetchSettings]);
  const renderEmailPreview = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white text-black p-6 rounded-lg w-full max-w-2xl max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Email Preview</h2>
          <button onClick={() => setShowPreview(false)} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="bg-white border border-gray-200 p-4 rounded-lg mb-4">
          <p><strong>From:</strong> {previewEmail.from}</p>
          <p><strong>To:</strong> {previewEmail.to}</p>
          <p><strong>Subject:</strong> {previewEmail.subject}</p>
        </div>
        <div className="bg-white border border-gray-200 p-4 rounded-lg mb-4">
          <div dangerouslySetInnerHTML={{ __html: previewEmail.body.replace(/\n/g, '<br>') }} />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleApproveFromPreview}
            className="bg-green-hover hover:bg-green-border border-green-border border-0.5 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
          >
            <ThumbsUp size={20} className="mr-2" />
            Approve
          </button>
          <button
            onClick={handleDenyFromPreview}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg inline-flex items-center"
          >
            <ThumbsDown size={20} className="mr-2" />
            Deny
          </button>
        </div>
      </div>
    </div>
  );

  const handleSendEmail = async (contactId) => {
  try {
    await axios.post(`https://sunlab-8da6b753879c.herokuapp.com/api/send-email/${contactId}`, {
      signature: settings.defaultSignature
    });
    setGeneratedEmails(prev => ({
      ...prev,
      [contactId]: { ...prev[contactId], status: 'sent' }
    }));
    setError('Email sent successfully');
  } catch (error) {
    console.error('Error sending email:', error);
    setError('Failed to send email. Please try again.');
  }
};

  const handleSendAllEmails = async () => {
    setError('');
    for (const contactId of contacts.map(c => c.id)) {
      if (generatedEmails[contactId] && generatedEmails[contactId].status === 'approved') {
        await handleSendEmail(contactId);
      }
    }
    setError('All eligible emails have been sent');
  };

  const handleSendSelectedEmails = async () => {
    setError('');
    setIsGenerating(true);
    let sent = 0;
    let failed = 0;

    for (const contactId of selectedContacts) {
      if (generatedEmails[contactId] && generatedEmails[contactId].status === 'approved') {
        try {
          await handleSendEmail(contactId);
          sent++;
        } catch (error) {
          console.error(`Error sending email for contact ${contactId}:`, error);
          failed++;
        }
      }
      setProgress((sent + failed) / selectedContacts.length * 100);
    }

    setIsGenerating(false);
    setProgress(0);
    setError(`Emails sent: ${sent}, Failed: ${failed}`);
    fetchGeneratedEmails();
  };





  const handleGenerateEmail = async (contactIds) => {
    setIsGenerating(true);
    setError('');
    setShouldStopGenerating(false);
    setProgress(0);

    const totalContacts = contactIds.length;
    let processedContacts = 0;

    for (const contactId of contactIds) {
      if (shouldStopGenerating) {
        break;
      }

      try {
        await axios.post('https://sunlab-8da6b753879c.herokuapp.com/api/generate-email', {
          contactId: contactId,
          userId: selectedUserId // Use the selected user's ID
        });
        processedContacts++;
        setProgress((processedContacts / totalContacts) * 100);
      } catch (error) {
        console.error('Error generating email:', error);
        setError('Failed to generate email. Please try again.');
      }

      await new Promise(resolve => setTimeout(resolve, 100));
    }

    setIsGenerating(false);
    setShouldStopGenerating(false);
    setProgress(0);
    if (contactIds.length > 0 && !shouldStopGenerating) {
      setCurrentContactId(contactIds[0]);
    }
    fetchGeneratedEmails();
  };

  const handleStopGenerating = () => {
    setShouldStopGenerating(true);
  };





const handleEditEmail = async (contactId, newContent) => {
  try {
    const emailId = generatedEmails[contactId]?.id;
    if (!emailId) {
      throw new Error('Email ID not found');
    }
    await axios.put(`https://sunlab-8da6b753879c.herokuapp.com/api/generated-emails/${emailId}`, { content: newContent });
    setGeneratedEmails(prev => ({
      ...prev,
      [contactId]: { ...prev[contactId], content: newContent }
    }));
  } catch (error) {
    console.error('Error editing email:', error);
    setError(`Failed to edit email: ${error.message}`);
  }
};

  // Update the handleApproveEmail function
  const handleApproveEmail = async (contactId) => {
    try {
      const emailId = generatedEmails[contactId]?.id;
      if (!emailId) {
        throw new Error('Email ID not found');
      }
      console.log(`Attempting to approve email ID: ${emailId} for contact ID: ${contactId}`);
      const response = await axios.put(`https://sunlab-8da6b753879c.herokuapp.com/api/generated-emails/${emailId}/approve`);
      console.log('Server response:', response.data);

      setGeneratedEmails(prev => ({
        ...prev,
        [contactId]: { ...prev[contactId], status: 'approved' }
      }));
      showNextEmail(contactId);
      setError(''); // Clear any previous errors
    } catch (error) {
      console.error('Error approving email:', error);
      if (error.response && error.response.status === 404) {
        setError(`Failed to approve email: Email not found. Contact ID: ${contactId}`);
        // Remove the non-existent email from the local state
        setGeneratedEmails(prev => {
          const newState = { ...prev };
          delete newState[contactId];
          return newState;
        });
      } else {
        setError(`Failed to approve email: ${error.message}`);
      }
    }
  };

  const handleDenyEmail = async (contactId) => {
    try {
      const emailId = generatedEmails[contactId]?.id;
      if (!emailId) {
        throw new Error('Email ID not found');
      }
      await axios.put(`https://sunlab-8da6b753879c.herokuapp.com/api/generated-emails/${emailId}/deny`);
      setGeneratedEmails(prev => ({
        ...prev,
        [contactId]: { ...prev[contactId], status: 'denied' }
      }));
      showNextEmail(contactId);
    } catch (error) {
      console.error('Error denying email:', error);
      setError(`Failed to deny email: ${error.message}`);
    }
  };

  const showNextEmail = (currentId) => {
    const emailIds = Object.keys(generatedEmails);
    const currentIndex = emailIds.indexOf(currentId.toString());
    if (currentIndex < emailIds.length - 1) {
      setCurrentContactId(parseInt(emailIds[currentIndex + 1]));
    } else {
      setCurrentContactId(null);
    }
  };

  const toggleContactSelection = (contactOrId) => {
  setSelectedContacts(prev => {
    let contactId;
    if (typeof contactOrId === 'object' && contactOrId !== null) {
      // If it's a contact object
      contactId = contactOrId.id;
    } else {
      // If it's already an ID
      contactId = contactOrId;
    }

    const newSelection = prev.includes(contactId)
      ? prev.filter(id => id !== contactId)
      : [...prev, contactId];

    console.log('Updated selected contacts:', newSelection);
    return newSelection;
  });
};

  const inputClass = `w-full ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500`;
  const buttonClass = `${isDarkMode ? 'bg-blue-600 hover:bg-blue-700' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline`;


  const getStatusIcon = (status) => {
    switch (status) {
      case 'generated':
        return <Mail className="text-blue-500" size={16} />;
      case 'approved':
        return <CheckSquare className="text-green-500" size={16} />;
      case 'pending':
        return <CalendarCheck2 className="text-orange-border" size={16} />;
      case 'sent':
        return <SendHorizontal className="text-green-500" size={16} />;
      case 'denied':
        return <X className="text-red-500" size={16} />;
      default:
        return null;
    }
  };

  const renderContactList = () => {
    const filteredContacts = contacts.filter(contact =>
      (!selectedIndustry || contact.industry === selectedIndustry) &&
      (!selectedAssignedTo || contact.created_by === selectedAssignedTo) &&
      (!selectedStatus || getContactStatus(contact.id) === selectedStatus) &&
      ((contact.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
      (contact.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
      (contact.company?.toLowerCase().includes(searchTerm.toLowerCase()) || ''))
    );

    return (
      <ul className="space-y-2 max-h-96 overflow-y-auto">
        {filteredContacts.map((contact) => (
          <li key={contact.id} className={`p-2 rounded-lg ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'} flex justify-between items-center`}>
            <span onClick={() => setCurrentContactId(contact.id)} className="cursor-pointer flex items-center">
              {getStatusIcon(generatedEmails[contact.id]?.status)}
              <span className="ml-2">{contact.company || ''}</span>
            </span>
            <button
              onClick={() => toggleContactSelection(contact)}
              className={`${buttonClass} mr-2 ${selectedContacts.includes(contact.id) ? 'bg-green-fill  hover:bg-green-hover border-green-border border-0.5' : 'border-white-border border-0.5 bg-gray-500 hover:bg-gray-600'}`}
            >
              <CheckCircle size={16} />
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const getContactStatus = (contactId) => {
    const email = generatedEmails[contactId];
    if (!email) return 'no email';
    return email.status;
  };


  const renderGenerateTab = () => {
    const industries = ['All', ...new Set(contacts.map(contact => contact.industry))].filter(Boolean);
    const assignedTos = ['All', ...new Set(contacts.map(contact => contact.created_by))].filter(Boolean);
    const statuses = ['All', 'No Email', 'Generated', 'Approved', 'Denied', 'Pending', 'Sent'];

    return (
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="lg:col-span-1">
          <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg`}>
            <h2 className="text-xl font-semibold mb-4">Select Contacts</h2>
            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search contacts..."
                  className={`${inputClass} pl-10 w-full`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>
            </div>
            <div className="mb-4">
              <Dropdown
                label="Industry"
                options={industries.map(industry => ({ label: industry, value: industry === 'All' ? '' : industry }))}
                onSelect={setSelectedIndustry}
                isDarkMode={isDarkMode}
                value={selectedIndustry}
              />
            </div>
            <div className="mb-4">
              <Dropdown
                label="Assigned To"
                options={assignedTos.map(assignedTo => ({ label: assignedTo, value: assignedTo === 'All' ? '' : assignedTo }))}
                onSelect={setSelectedAssignedTo}
                isDarkMode={isDarkMode}
                value={selectedAssignedTo}
              />
            </div>
            <div className="mb-4">
              <Dropdown
                label="Email Status"
                options={statuses.map(status => ({ label: status, value: status === 'All' ? '' : status.toLowerCase() }))}
                onSelect={setSelectedStatus}
                isDarkMode={isDarkMode}
                value={selectedStatus}
              />
            </div>
            {renderContactList()}
          </div>
        </div>

        <div className="lg:col-span-2">
  <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg`}>
    <h2 className="text-xl font-semibold mb-4">Generated Email</h2>
    <div className="mb-4">
      <label className="block mb-2">Generate Email As:</label>
      <Dropdown
        label="Select User"
        options={[
          { label: "Select User", value: "" },
          ...users.map(user => ({
            label: `${user.default_name} (${user.default_email})`,
            value: user.user_id
          }))
        ]}
        onSelect={(value) => setSelectedUserId(value)}
        isDarkMode={isDarkMode}
        value={selectedUserId}
      />
    </div>
    {currentContactId && generatedEmails[currentContactId] && (
      <div className="mb-4">
        <h3 className="font-semibold mb-2">
          Email for {contacts.find(c => c.id === currentContactId)?.company}
        </h3>
        <textarea
          value={generatedEmails[currentContactId].content}
          onChange={(e) => handleEditEmail(currentContactId, e.target.value)}
          className={`${inputClass} w-full h-64 mb-4`}
        />
        <div className="flex flex-wrap justify-between gap-2">
          <button
            onClick={() => handlePreviewEmail(currentContactId)}
            className={`${buttonClass} bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5`}
          >
            <Eye size={20} className="inline-block mr-2" /> Preview
          </button>
          <button
            onClick={() => handleApproveEmail(currentContactId)}
            className={`${buttonClass} bg-green-fill hover:bg-green-hover border-green-border border-0.5`}
            disabled={generatedEmails[currentContactId].status === 'approved' || generatedEmails[currentContactId].status === 'sent'}
          >
            <ThumbsUp size={20} className="inline-block mr-2" /> Approve
          </button>
          <button
            onClick={() => handleDenyEmail(currentContactId)}
            className={`${buttonClass} bg-red-fill hover:bg-red-hover border-red-border border-0.5`}
            disabled={generatedEmails[currentContactId].status === 'denied' || generatedEmails[currentContactId].status === 'sent'}
          >
            <ThumbsDown size={20} className="inline-block mr-2" /> Deny
          </button>
          <button
            onClick={() => toggleContactSelection(currentContactId)}
            className={`${buttonClass} ${selectedContacts.includes(currentContactId) ? 'bg-green-fill  hover:bg-green-hover border-green-border border-0.5' : 'border-white-border border-0.5 bg-gray-500 hover:bg-gray-600'}`}
          >
            <CheckCircle size={20} className="inline-block" />
          </button>
        </div>
      </div>
    )}
    {!currentContactId && (
      <p>No email selected. Click on a contact to view their generated email.</p>
    )}
  </div>
</div>

        <div className="lg:col-span-1">
          <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg`}>
            <h2 className="text-xl font-semibold mb-4">Actions</h2>
            <div className="space-y-2">
              <button
                onClick={() => handleGenerateEmail(selectedContacts)}
                disabled={selectedContacts.length === 0 || isGenerating}
                className={`${buttonClass} w-full bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5`}
              >
                <Sparkles size={20} className="inline-block mr-2" />
                Generate Selected
              </button>
              <button
                onClick={() => handleGenerateEmail(contacts.map(c => c.id))}
                disabled={isGenerating}
                className={`${buttonClass} w-full bg-yellow-fill hover:bg-yellow-hover border-yellow-border border-0.5`}
              >
                <Sparkles size={20} className="inline-block mr-2" />
                Generate All
              </button>
              <button
                onClick={handleSendSelectedEmails}
                disabled={selectedContacts.length === 0}
                className={`${buttonClass} w-full bg-green-fill hover:bg-green-hover border-green-border border-0.5`}
              >
                <Send size={20} className="inline-block mr-2" />
                Send Selected
              </button>
              <button
                onClick={handleSendAllEmails}
                className={`${buttonClass} w-full bg-green-fill hover:bg-green-hover border-green-border border-0.5`}
              >
                <Send size={20} className="inline-block mr-2" />
                Send All
              </button>
              <button
                onClick={() => handleSelectEmailsToSchedule(selectedContacts.filter(id => generatedEmails[id]?.status === 'approved'))}
                disabled={selectedContacts.filter(id => generatedEmails[id]?.status === 'approved').length === 0}
                className={`${buttonClass} w-full bg-orange-fill hover:bg-orange-hover border-orange-border border-0.5`}
              >
                <Calendar size={20} className="inline-block mr-2" />
                Schedule Selected
              </button>
              <button
                onClick={() => handleSelectEmailsToSchedule(contacts.map(c => c.id).filter(id => generatedEmails[id]?.status === 'approved'))}
                disabled={contacts.filter(c => generatedEmails[c.id]?.status === 'approved').length === 0}
                className={`${buttonClass} w-full bg-orange-fill hover:bg-orange-hover border-orange-border border-0.5`}
              >
                <Calendar size={20} className="inline-block mr-2" />
                Schedule All Approved
              </button>
            </div>
            {isGenerating && (
              <div className="mt-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className="bg-yellow-fill h-2.5 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <p className="text-center mt-2">{Math.round(progress)}% Complete</p>
                <button
                  onClick={handleStopGenerating}
                  className={`${buttonClass} w-full mt-2 bg-red-fill hover:bg-red-hover border-red-border border-0.5`}
                >
                  <StopCircle size={20} className="inline-block mr-2" />
                  Stop Generating
                </button>
              </div>
            )}
          </div>
          {renderScheduledEmails()}
        </div>
      </div>
    );
  };

  const renderScheduledEmails = () => {
      const groupedEmails = scheduledEmails.reduce((acc, email) => {
        const key = email.scheduled_time;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(email);
        return acc;
      }, {});

      return (
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Scheduled Emails</h3>
          {Object.entries(groupedEmails).map(([time, emails]) => (
            <div key={time} className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-200'} p-4 rounded-lg mb-2 flex justify-between items-center`}>
              <div>
                <p>Scheduled: {new Date(time).toLocaleString()}</p>
                <p>Emails: {emails.length}</p>
                <p>Status: {emails[0].status}</p>
              </div>
              <button
                onClick={() => handleDeleteScheduledEmails(emails.map(e => e.id))}
                className={`${buttonClass} bg-red-500 hover:bg-red-600`}
              >
                <Trash size={16} />
              </button>
            </div>
          ))}
        </div>
      );
    };

    const handleDeleteScheduledEmails = async (ids) => {
        try {
          await Promise.all(ids.map(id =>
            axios.delete(`https://sunlab-8da6b753879c.herokuapp.com/api/scheduled-emails/${id}`)
          ));
          fetchScheduledEmails();
          setError('Scheduled emails deleted successfully');
        } catch (error) {
          console.error('Error deleting scheduled emails:', error);
          setError('Failed to delete scheduled emails. Please try again.');
        }
      };


return (
  <div className={`container mx-auto px-4 py-8 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
    <h1 className="text-3xl font-bold mb-6">AI Email Generator</h1>

    {error && <p className="text-red-500 mb-4">{error}</p>}

    {activeTab === 'generate' && renderGenerateTab()}
    {showPreview && renderEmailPreview()}
    {showScheduler && renderScheduler()}
  </div>
);
};

export default EmailGenerator;
