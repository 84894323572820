import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SearchLogs = ({ isDarkMode }) => {
  const [completedSearches, setCompletedSearches] = useState([]);

  useEffect(() => {
    fetchCompletedSearches();
  }, []);

  const fetchCompletedSearches = async () => {
    try {
      const response = await axios.get('https://sunlab-8da6b753879c.herokuapp.com/api/completed-searches');
      setCompletedSearches(response.data);
    } catch (error) {
      console.error('Error fetching completed searches:', error);
    }
  };

  return (
    <div className={`max-w-6xl mx-auto p-6 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <h2 className="text-3xl font-bold mb-8">Search Logs</h2>
      <h3 className="text-2xl font-bold mb-4">Completed Searches</h3>
      <div className="overflow-x-auto">
        <table className="w-full mb-8">
          <thead>
            <tr>
              <th className="text-left px-2 py-1">User</th>
              <th className="text-left px-2 py-1">Category</th>
              <th className="text-left px-2 py-1">Zip Code</th>
              <th className="text-left px-2 py-1">Limit</th>

              <th className="text-left px-2 py-1">Results Found</th>
              <th className="text-left px-2 py-1">Pre-Filter Results</th>
              <th className="text-left px-2 py-1">Searched Zip Codes</th>
              <th className="text-left px-2 py-1">Date Completed</th>
            </tr>
          </thead>
          <tbody>
            {completedSearches.map((search) => (
              <tr key={search.id} className="hover:bg-gray-200 dark:hover:bg-gray-700">
                <td className="px-2 py-1">{search.user_id}</td>
                <td className="px-2 py-1">{search.category}</td>
                <td className="px-2 py-1">{search.zip_code}</td>
                <td className="px-2 py-1">{search.result_limit}</td>
                
                <td className="px-2 py-1">{search.results_found}</td>
                <td className="px-2 py-1">{search.total_results_pre_filter}</td>
                <td className="px-2 py-1">{search.searched_zip_codes ? search.searched_zip_codes.join(', ') : 'N/A'}</td>
                <td className="px-2 py-1">{new Date(search.completed_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchLogs;
